<template>
  <div class="flex flex-row-reverse justify-between">
    <header class="flex flex-row items-start justify-end">
      <button @click="$emit('go-to-detail') " class="flex text-gray-500 hover:text-gray-600 bg-gray-200 rounded-full">
        <ChevronRightIcon class="self-center h-5 w-5"/>
      </button>
    </header>
    <div class="my-3 mx-2">
      <main>
        <div class="flex flex-row items-start space-x-4">
          <h1 class="text-base font-bold">{{ building.name }}</h1>
          <dashboard-risk-badge class="flex-none" :badgeStyle="'medium'" :risk="building.risk" />
        </div>
        <p class="mt-1 text-sm text-gray-500 font-normal">{{ building.shortAddress }}<br>{{ building.mapAddress }}</p>
      </main>
      <footer class="flex flex-row mt-2 justify-start space-x-2">
        <dashboard-risk-count-badge v-for="risk in building.spaceRiskRatingCount.filter(rating => rating.count > 0)" :key="risk.name" :risk="risk.name" :count="risk.count" />
      </footer>
    </div>
  </div>
</template>

<script>
import { DashboardBuilding } from '@/models/dashboard';
import { ChevronRightIcon } from '@heroicons/vue/solid';

import DashboardRiskBadge from '@/components/dashboard/DashboardRiskBadge.vue';
import DashboardRiskCountBadge from '@/components/dashboard/DashboardRiskCountBadge.vue/';

export default {
  components: {
    DashboardRiskBadge,
    ChevronRightIcon,
    DashboardRiskCountBadge
  },
  props: {
    building: {
      type: DashboardBuilding,
      required: true
    }
  }
};
</script>