import { DashboardBuilding, RiskState } from './dashboard';

function sortMapPins(firstBuilding: DashboardBuilding, secondBuilding: DashboardBuilding): number {
  if (firstBuilding.risk.state === secondBuilding.risk.state) {
    return sameRiskStateDifference(firstBuilding, secondBuilding);
  }
  const riskStateValues = Object.values(RiskState);
  return riskStateValues.indexOf(firstBuilding.risk.state) - riskStateValues.indexOf(secondBuilding.risk.state);
}

function sameRiskStateDifference(firstBuilding: DashboardBuilding, secondBuilding: DashboardBuilding): number {
  return (firstBuilding.risk.riskValue ?? 0) - (secondBuilding.risk.riskValue ?? 0);
}

export {
  sortMapPins
};